import React, { useContext, useEffect, useRef, useState, useCallback, lazy } from "react";
import { Accordion, Button, Modal, OverlayTrigger, ProgressBar, Container,Form,FormCheck,Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiService, setAuthorization } from '../../services/ApiService';
import axios from 'axios';
import { useMyContext } from '../../MyContext';
import './css/quickscan.css';
import { log } from "@tensorflow/tfjs";



const HelperModal = (intialData) => {
    const { modalType, formSubmit, show, hideModal, modalData, AmSize = "", AmClass = "", formType = "",mClass } = intialData
    const navigate = useNavigate();
    const { userProfile, setUserProfile } = useMyContext();
    const [formRes, setFormRes] = useState({ staus: false, err: false, data: {} })
    const [formSubmitted, setFormSbmt] = useState(false)
    const [modalFormData, setModalFormData] = useState({})
    const [selectedOption, setSelectedOption] = useState(11);
    const [quickScanPay, setQuickScanPay] = useState(null);
    const [paymentPlanDetail, setPaymentPlanDetail] = useState({});
    const [loading, setLoading] = useState(false);

    
  const { register, handleSubmit, watch, setValue, reset, resetField, trigger, clearErrors, setError, formState: { errors } } = useForm();

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    if (value === '10' || value === '11') {
        setQuickScanPay(50)
    }
    else if(value == '9'){
        setQuickScanPay(100)
    }
  };

    useEffect(() => {
        if (modalType == 'upload_pnp_modal') {
           // setMsgErr('')
           // setUploadErr('')
           // setShowLoader(false)
           // setUploadFiles(null)
        }
        if (modalType === 'quick_scan') {
            setSelectedOption('11');
            setQuickScanPay(50); // Set default payment value
          }
        if (modalType === 'payment_link') {
          getPaymentPlan(modalData.payplanID)
          }

    }, []);

    const handleModalClose = (data = null) => {
        hideModal(data)
    };

    const getPaymentPlan = async (planID) => {
      let payloadUrl = `payment/getPaymentPlanDetail/${planID}`;
      let method = "GET"
      let formData = {}
      const res = await ApiService.fetchData(payloadUrl,method,formData)
      if( res && res.status_code){
          let result = res.results[0];
          setPaymentPlanDetail(result);
      }
      
      };


// const generatePaymentLink = async(planID)=>{
//     let payloadUrl = "payment/generateRzPaymentLink"
//     let method = "POST"
//     let formData = {plan_id:parseInt(planID)}
//    // const res = await ApiService.fetchData(payloadUrl,method,formData)
//    try {
//     setLoading(true);
//     const response = await axios({
//       method: method,
//       url: payloadUrl,
//       data: formData,
//     });
//     if (response && response.status_code == 'dc200') {
//      // Redirect user to the Razorpay payment page with the order ID
//      const order = response.results;
//      const RZorderID = order.id;
//      const options = {
//       key: process.env.RAZORPAY_KEY,
//       amount: order.amount_due,
//       currency: order.currency,
//       name: 'DecXpert',
//       description: 'Digital Pathology Payment',
//       order_id: order.id,
//       handler: async function (response) {
//         let payloadUrl = "payment/verifyRzPayment"
//         let method = "POST"
//         let formData = {paymentId:response.razorpay_payment_id,orderID:RZorderID}
//         const resp = await axios({
//           method: method,
//           url: payloadUrl,
//           data: formData,
//         });
//         if (resp && resp.status_code == 'dc200') {
//           setLoading(false);
//           handleModalClose();
//           window.location.assign("/dashboard");
//         }
//       },
//       prefill: {
//         name: userProfile.fullname,
//         email: '',
//         contact: userProfile.mobile,
//       },
//       theme: {
//         color: '#3399cc',
//       },
//       modal: {
//         ondismiss: function() {
//           setLoading(false);
//         }
//       }
//     };
//     const rzp = new window.Razorpay(options);
//     rzp.open();
//     } else {
//       // Handle other cases if needed
//       console.error('Error:', response.data ? response.data.message : 'Unexpected response format');
//     }
//   } catch (error) {
//     console.error('Error:', error);
//     // Handle errors if necessary
//   }
    
    
// }

const generatePaymentLink = async(planID) => {
  let payloadUrl = "payment/generateRzPaymentLink"
  let method = "POST"
  let formData = {plan_id: parseInt(planID)}
  
  try {
      setLoading(true);
      const response = await axios({
          method: method,
          url: payloadUrl,
          data: formData,
      });
      
      console.log("Payment link response:", response); // Add this to debug
      
      // Check the correct condition based on your API response
      if (response && response.status_code === 'dc200') {
          const order = response.results;
          const RZorderID = order.id;
          
          const options = {
              key: process.env.RAZORPAY_KEY,
              amount: order.amount_due,
              currency: order.currency,
              name: 'DecXpert',
              description: 'Digital Pathology Payment',
              order_id: order.id,
              handler: async function (rzpResponse) {
                  let payloadUrl = "payment/verifyRzPayment"
                  let method = "POST"
                  let formData = {
                      paymentId: rzpResponse.razorpay_payment_id,
                      orderID: RZorderID,
                      razorpay_signature: rzpResponse.razorpay_signature
                  }
                  
                  try {
                      const resp = await axios({
                          method: method,
                          url: payloadUrl,
                          data: formData,
                      });
                     // console.log(resp);
                      
                      
                      if (resp && resp.status_code === 'dc200') {
                          setLoading(false);
                          handleModalClose();
                          window.location.assign("/dashboard");
                      } else {
                          setLoading(false);
                          alert("Payment verification failed. Please contact support.");
                      }
                  } catch (error) {
                      console.error("Verification error:", error);
                      setLoading(false);
                      alert("Payment verification error. Please try again.");
                  }
              },
              prefill: {
                  name: userProfile.fullname,
                  email: '',
                  contact: userProfile.mobile,
              },
              theme: {
                  color: '#3399cc',
              },
              modal: {
                  ondismiss: function() {
                      setLoading(false);
                  }
              }
          };
          
          const rzp = new window.Razorpay(options);
          rzp.open();
      } else {
          console.error('Error:', response.data ? response.data.message : 'Unexpected response format');
          setLoading(false);
          alert("Failed to create payment. Please try again.");
      }
  } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      alert("Error connecting to payment service. Please try again later.");
  }
}


    const onSubmit = async (data = {}) => {

        if(modalType === 'quick_scan'){
            
            if(data != ''){
                console.log(data);
                setFormSbmt(true)
               // data.groupIndex = modalData?.gIndex;
               // data.questionIndex = modalData?.qIndex;
                let res = await formSubmit(modalType, data)
                //console.log(res);
                if ((res && res.message == 'Success')) {
                    setFormSbmt(false)
                    handleModalClose()
                }
                else if ((res && res.message != 'Success') || res == false) {
                  setFormSbmt(false);
                    handleModalClose();
                    alert('Mobile number already registered. Please login');
                }
                setFormSbmt(false)
            }
        }
        // let stat = { status: false, err: false, data: {} }
        // setFormRes(stat)
       
        // return false
    }
    
    if (modalType == 'quick_scan') {
        return (
            <Modal
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            keyboard={false}
            size="md"
            className="custom-modal task_details_modal"
            scrollable={true}
        >
            <Modal.Header closeButton className="py-2 bg_15 d-flex align-items-center text-white" style={{backgroundColor:"#214a80"}}>
                <Modal.Title className="fs-12">Quick Scan</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:"#214a80"}}>
            <div className="login-dark_quick">
            <Container className="modal-body-container">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Form.Group>
               
                  <Form.Control type="email" placeholder="Email" className="form-control" {...register("email", { required: true })} />
                  {errors.email?.type === "required" && <small className="form-text text-danger">email is required</small>}
                </Form.Group>
                <Form.Group>
                  <Form.Control type="number" placeholder="Mobile Number" className="form-control" {...register("mobile", { required: "mobile number is required",maxLength: {value: 10,message: "enter only 10 digit mobile number" },minLength: {value: 10,message: "Enter at least 10 digits for the mobile number"} })} />
                  {errors.mobile && <small className="form-text text-danger">{errors.mobile.message}</small>}
                </Form.Group>
                <Form.Group>
                  <Form.Control type="password" placeholder="Password" className="form-control" {...register("password", { required: "password is required",minLength: {value: 6,message: "minimum length should be 6 characters" } })} />
                  {errors.password && <small className="form-text text-danger">{errors.password.message}</small>}
                </Form.Group>

                <Form.Group className="row">
                   
                 <Form.Label className="col-lg-12 pt-5">Select Scan Type</Form.Label>
                    <div className="col-lg-6">
                        <Form.Check  type="radio" {...register("scantype")} label="Digital Pathology" id="DP" value="11" checked={selectedOption === '11'} onChange={() => handleOptionChange('11')}/>
                    </div>
                    <div className="col-lg-6">
                        <Form.Check  type="radio" label="Tuberculosis" {...register("scantype")} id="TB" value="10" checked={selectedOption === '10'} onChange={() => handleOptionChange('10')} />
                    </div>
                    <div className="col-lg-12">
                        <Form.Check  type="radio" label="Digital Pathology + TB Scan" {...register("scantype")} id="DPTB" value="9" checked={selectedOption === '9'} onChange={() => handleOptionChange('9')} />
                    </div>
                </Form.Group>
                <Form.Group>
      
                  <Button className="btn btn-primary btn-block" type="submit">
                    Pay ₹{quickScanPay}
                  </Button>
                </Form.Group>
               
              </form>
            </Container>
          </div>
            </Modal.Body>
        </Modal>
        )
    }
    if (modalType == 'payment_link') {
      return (
          <Modal
          show={show}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
          size="md"
          className="custom-modal"
          scrollable={true}
      >
          <Modal.Header closeButton>
              <Modal.Title className="fs-12">{paymentPlanDetail.plan_name} Plan Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="container ">
                <center>
                  <p>Make the payment for <span className="text-danger">{(paymentPlanDetail.test_type_id==1)?'Digital Pathlology + TB Scan':(paymentPlanDetail.test_type_id==2)?'Digital Pathlology or TB Scan':''}  ₹{paymentPlanDetail.plan_cost}</span></p>
                <button className="btn btn-colour-1" onClick={() => generatePaymentLink(modalData.payplanID)}>
                {loading ? (
                  <Spinner animation="border" role="status" variant="light" size='sm'>
                  </Spinner>
                   ) : (<></>)}
                   Complete Payment</button>
                </center>
               
              </div>
              
            </div>
          </Modal.Body>
      </Modal>
      )
  }

}

export default HelperModal